<template>
    <footer class="footer-bar">
        <div>
            <span>&copy; {{year}} Matric Live</span>
        </div>
        <div class="options responsive-remove">
            <span>About Us</span>
            <ul>
                <li><a><router-link class="footer-item" to="/">Home</router-link></a></li>
                <li><a><router-link class="footer-item" to="/ourstory">Our Story</router-link></a></li>
                <li><a><router-link class="footer-item" to="/careers">Careers</router-link></a></li>
            </ul>
        </div>
        <div class="options">
            <span>More Info</span>
            <ul>
                <li><a><router-link class="footer-item" to="/terms_of_service">Terms of Service</router-link></a></li>
                <li><a><router-link class="footer-item" to="/privacy">Privacy Policy</router-link></a></li>
                <!-- <li><a><router-link class="footer-item" to="/personal_information">Do Not Sell My Personal Information</router-link></a></li>
                <li><a><router-link class="footer-item" to="/legal_notes">More Legal Notes</router-link></a></li> -->
            </ul>
        </div>
        <div class="options">
            <span>Contact Us</span>
            <ul>
                <li><a><router-link class="footer-item" to="/support">Customer Support</router-link></a></li>
                <li><a class="footer-item" href="mailto:">info@matriclive.com</a></li>
            </ul>
        </div>
        <!-- <button class="web-btn">
            Staff Access
          </button> -->
    </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
        year: new Date().getFullYear()
    }
  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer-bar {
    padding: 9% 0;
    display: flex;
    /* background-color: #f47622; */
    background: linear-gradient(90.32deg, #7932E8 28.37%, #9B51E0 97.47%);
    justify-content: space-evenly;

}

    ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: transparent;
}

li a > .footer-item {
  display: block;
  color: white;
  text-align: center;
  padding: 5px 30px;
  text-decoration: none;
  margin: 5px 0px;
}

li .footer-item {
  display: block;
  color: white;
  text-align: center;
  padding: 5px 30px;
  text-decoration: none;
  margin: 5px 0px;
}

button.web-btn {
    background-color: white;
    height: 46px;
    border-radius: 50px;
    border: thin;
    width: 163px;
    outline: none;
    box-shadow: 0px 5px 3px #0000008f;
    margin: 0px 10px;
    font-weight: bold;
    /* color: #f47622; */
    background: linear-gradient(90.32deg, #7932E8 28.37%, #9B51E0 97.47%);
    margin-left: 60px;
    font-size: 15px;
    align-self: center;
}

.options {
    text-align: center;
}

span {
    color: white;
    font-weight: bold;
}

div:first-child {
    align-self: center;
    font-size: small;
}

    @media screen and (max-width: 950px) {
        .footer-bar div.responsive-remove {
            display: none;
        }

        .footer-bar {
            display: block;
            overflow: hidden;
        }

        .footer-bar div.options {
            margin: 5%;
        }

        button.web-btn {
            margin: 0;
        }
    }

</style>
