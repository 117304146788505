<template>
  <div id="app">
    <HeaderBar v-if="!load"/>
    <div id="hide">
      <router-view/>
    </div>
    <div class="loader-cover" v-if="load">
      <div class="loader">
        <!-- <img src="@/assets/homepage/icon 1.svg" alt="">
        <pulse-loader :loading="loading" :color="orange" :size="size"></pulse-loader> -->
        <Loader/>
      </div>
    </div>
    <Footer v-if="!load"/>
  </div>
</template>

<script>
import HeaderBar from "./components/HeaderBar.vue";
import Footer from "./components/Footer.vue";
import Loader from "./components/Loader.vue";
// import PulseLoader from "vue-spinner/src/PulseLoader.vue";
// import _ from "async-assets-loader/dist/async-assets-loader.js"

export default {
  name: "App",
  components: {
    HeaderBar,
    Footer,
    Loader
  },
  

  // beforeCreate () {
  //   ;
  // },
   data() {
      return {
        load: true,
        orange: "orange",
        // color: "red",
        size: "15px"
      };
    },

    mounted () {
      var hide = document.getElementById("hide");
      hide.classList.add("hidden");
      hide.ontouchend = (e) => {
    e.preventDefault();
};
      setTimeout(() => {
        this.load = false;
        hide.classList.remove("hidden");
      }, 4000);
    },
};
</script>

<style>
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
   url(./fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}
#app {
  font-family: "Poppins";
  background-color: white !important;
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.hidden {
  visibility: hidden;
  
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.loader {
    position: absolute;
    padding: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loader img {
  height: 15vh;
  margin: 8% 0%;
}

.loader-cover {
  height: 100vh;
}
</style>
