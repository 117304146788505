<template>
  <section>
    <div class="main-div">
      <div class="title">Matric Live</div>
      <!-- spinner -->
      <div class="spinner-container">
        <div class="spinner">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-div {
  text-align: center;
}

section {
    overflow-y: hidden;
}

@keyframes animationgradienttitle {
  0% {
    background-position: 0 1600px;
  }
  100% {
    background-position: 1600px 0;
  }
}

/* title animated gradient */
.title {
  text-align: center;
  text-align: -webkit-center;
  font-size: xxx-large;
  font-family: Avenir,Helvetica,Arial,sans-serif !important;
  font-weight: 900;
  color: #e96812;
  background: -webkit-linear-gradient(left, #8b5af3, #eda04b, #f05a23);
  background-size: 1600px 200px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-duration: 1s;
  animation-name: animationgradienttitle;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

/* spinner */
.spinner-container {
  margin: 3.5% 0%;
  text-align: center;
  text-align: -webkit-center;
}

.spinner {
  width: 60px;
  height: 60px;
  position: relative;
}

.spinner div {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 10px solid transparent;
  border-top-color: #e96812;
  border-radius: 50%;
  animation: spinnerOne 1.2s linear infinite;
}

.spinner div:nth-child(2) {
  border: 10px solid transparent;
  border-bottom-color: #e96812;
  animation: spinnerTwo 1.2s linear infinite;
}

@keyframes spinnerOne {
  0% {
    transform: rotate(0deg);
    border-width: 10px;
  }
  50% {
    transform: rotate(180deg);
    border-width: 1px;
  }
  100% {
    transform: rotate(360deg);
    border-width: 10px;
  }
}

@keyframes spinnerTwo {
  0% {
    transform: rotate(0deg);
    border-width: 1px;
  }
  50% {
    transform: rotate(180deg);
    border-width: 10px;
  }
  100% {
    transform: rotate(360deg);
    border-width: 1px;
  }
}

@media screen and (max-width: 544px){
    .title {
        font-size: large;
        font-weight: 900;
    }
}
</style>
