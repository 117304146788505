<template>
  <nav class="navbar" id="site-header">
    <a class="navbar-brand logo-image" href="#">
      <div class="home-icon">
        <router-link to="/home">
          <img src="../assets/nav_logo.svg" alt width="40px" />
        </router-link>
      </div>
    </a>

    <ul>
      <li>
        <div class="wrapper">
          <span class="square individual">
            <a class="ninth before after">
              <router-link to="/ourstory"> Our Story </router-link>
            </a>
          </span>
        </div>
      </li>

      <li>
        <div class="wrapper">
          <span class="square individual">
            <a class="ninth before after">
              <router-link to="/impact"> Impact </router-link>
            </a>
          </span>
        </div>
      </li>
      <li>
        <div class="wrapper">
          <span class="square individual">
            <a class="ninth before after">
              <router-link to="/careers"> Careers </router-link>
            </a>
          </span>
        </div>
      </li>
      <li>
        <div class="wrapper responsive-add">
          <span class="square individual">
            <a>
              <router-link class="down-item" to="/partners_and_media"
                >Partners & Media</router-link
              ></a
            >
          </span>
        </div>
      </li>
      <li>
        <div class="wrapper responsive-add">
          <span class="square individual">
            <a>
              <router-link class="down-item" to="/board_and_investor"
                >Board & Investors</router-link
              ></a
            >
          </span>
        </div>
      </li>
      <li>
        <div class="wrapper responsive-add">
          <span class="square individual">
            <a>
              <router-link class="down-item" to="/contact_us"
                >Contact Us</router-link
              ></a
            >
          </span>
        </div>
      </li>
      <li>
        <div class="wrapper responsive-remove">
          <div class="drop-down dropdown-bubble">
            <span class="square individual">
              <a class="ninth before after dropbtn" @click="openDropDown">
                Company
                <!-- <router-link to="/company"> Company </router-link> -->
              </a>
            </span>
            <div id="drop-down-items" class="dropdown-content">
              <a>
                <router-link class="down-item" to="/partners_and_media"
                  >Partners & Media</router-link
                ></a
              >
              <a>
                <router-link class="down-item" to="/board_and_investor"
                  >Board & Investors</router-link
                ></a
              >
              <a>
                <router-link class="down-item" to="/help_and_faq"
                  >Help/FAQ</router-link
                ></a
              >
              <a>
                <router-link class="down-item" to="/contact_us"
                  >Contact us</router-link
                ></a
              >
            </div>
          </div>
        </div>
      </li>

      <li>
        <router-link to="/matriclive_web">
          <button class="web-btn">Matric Live web</button>
        </router-link>
      </li>
    </ul>
    <button class="nav-toggler">
      <span></span>
    </button>
  </nav>
</template>

<script>
export default {
  name: "HeaderBar",
  methods: {
    openDropDown() {
      document.getElementById("drop-down-items").classList.toggle("show");

      window.onclick = function (event) {
        if (!event.target.matches(".dropbtn")) {
          var dropdowns = document.getElementsByClassName("dropdown-content");
          var i;
          for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.classList.contains("show")) {
              openDropdown.classList.remove("show");
            }
          }
        }
      };
    },
  },
  mounted() {
    const navToggler = document.querySelector(".nav-toggler");
    const navMenu = document.querySelector(".navbar ul");
    const navLinks = document.querySelectorAll(".navbar a");

    // load all event listners
    allEventListners();

    // functions of all event listners
    function allEventListners() {
      // toggler icon click event
      navToggler.addEventListener("click", togglerClick);
      // nav links click event
      navLinks.forEach((elem) => elem.addEventListener("click", navLinkClick));
    }

    // togglerClick function
    function togglerClick() {
      navToggler.classList.toggle("toggler-open");
      navMenu.classList.toggle("open");
    }

    // navLinkClick function
    function navLinkClick() {
      if (navMenu.classList.contains("open")) {
        navToggler.click();
      }
    }
  },
};

var prevScrollpos = window.pageYOffset;
window.onscroll = function () {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("site-header").style.top = "0";
  } else {
    document.getElementById("site-header").style.top = "-100px";
  }
  prevScrollpos = currentScrollPos;
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar {
  position: sticky;
  top: 0;
  z-index: 105;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100px;
  /* background-color: #f47622; */
  background: linear-gradient(90.32deg, #7932E8 28.37%, #9B51E0 97.47%);
  -webkit-transition: background 0.2s ease-in-out 0.5s;
  transition: background 0.2s ease-in-out 0.5s;
  pointer-events: all;
}

#site-header {
  transition: top 0.3s;
}

.navbar a img {
  margin: 0;
}

.home-icon {
  display: table-caption;
  text-align: center;
}

.navbar-brand {
  margin: 0px 43px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  display: inline;
}
nav {
  flex-wrap: wrap;
  justify-content: space-between;
  backdrop-filter: blur(5px);
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 10px;
  overflow: hidden;
  background: transparent;
  display: flex;
  align-items: center;
}

ul li div.responsive-add {
  display: none;
}

li {
  margin-left: 10px;
}

button.web-btn {
  background-color: white;
  height: 46px;
  border-radius: 50px;
  border: thin;
  width: 163px;
  outline: none;
  box-shadow: 0px 5px 3px #0000001f;
  margin: 0px 10px;
  font-weight: bold;
  /* color: #f47622; */
  background: linear-gradient(90.32deg, #7932E8 28.37%, #9B51E0 97.47%);
  color:#fff;
  margin-left: 30px;
  font-size: 15px;
  height: 56px;
  margin-right: 40px;
}

a,
a:visited,
a:hover,
a:active {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  transition: 0.5s color ease;
  text-decoration: none;
  color: #81b3d2;
  font-size: 16px;
}

a:hover {
  color: #d73444;
}

a.before:before,
a.after:after {
  content: "";
  transition: 0.5s all ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}
a.before:before {
  top: -0.25em;
}
a.after:after {
  bottom: -0.25em;
}
a.before:before,
a.after:after {
  height: 5px;
  height: 0.15rem;
  width: 0;
  background: #fff;
}

.square {
  box-sizing: border-box;
  margin-left: -0.4em;
  position: relative;
  font-size: 16px;
  overflow: hidden;
}
.square a {
  position: static;
  padding: 0.3em 0.4em;
  font-weight: bold;
  color: #fff;
  padding: 20px;
  text-align: center;
}
.square:before,
.square:after {
  content: "";
  box-sizing: border-box;
  transition: 0.25s all ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  width: 5px;
  width: 0.15rem;
  height: 0;
  background: #fff;
}
.square:before {
  left: 0;
  bottom: -0.2em;
}
.square.individual:before {
  transition-delay: 0.6s;
}
.square:after {
  right: 0;
  top: -0.2em;
}
.square.individual:after {
  transition-delay: 0.2s;
}
.square a:before {
  left: 0;
  transition: 0.25s all ease;
}
.square a:after {
  right: 0;
  transition: 0.25s all ease;
}
.square.individual a:after {
  transition: 0.25s all ease 0.4s;
}
.square:hover:before,
.square:hover:after {
  height: calc(100% + 0.4em);
}
.square:hover a:before,
.square:hover a:after {
  width: 100%;
}

/* .dropbtn:hover, .dropbtn:focus {
  background-color: #2980B9;
} */

.drop-down {
  cursor: pointer;
}

.dropdown-content {
  margin-top: 2%;
  border-radius: 6px;
  text-align: start;
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content > a .down-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  /* color: #f47622; */
  background: linear-gradient(90.32deg, #7932E8 28.37%, #9B51E0 97.47%);
  font-weight: bold;
}

.show {
  display: block;
}

.dropdown-bubble .dropdown-content:before,
.dropdown-bubble .dropdown-content:after {
  content: " ";
  display: block;
  border-style: solid;
  border-width: 0 0.5em 0.8em 0.5em;
  border-color: transparent;
  position: absolute;
  left: 2em;
}

.dropdown-bubble .dropdown-content:before {
  top: -0.8em;
}

.dropdown-bubble .dropdown-content:after {
  top: -0.7em;
  border-bottom-color: #fff;
}

.nav-toggler {
  /* border: 3px solid #fff; */
  outline-style: none;
  outline: none;
  border: none;
  padding: 3px;
  background-color: transparent;
  cursor: pointer;
  height: 39px;
  display: none;
}
.nav-toggler span,
.nav-toggler span:before,
.nav-toggler span:after {
  width: 28px;
  height: 2px;
  background-color: #fff;
  display: block;
  transition: 0.3s;
}
.nav-toggler span:before {
  content: "";
  transform: translateY(-9px);
}
.nav-toggler span:after {
  content: "";
  transform: translateY(6px);
}
.nav-toggler.toggler-open span {
  background-color: transparent;
}
.nav-toggler.toggler-open {
  border: 2.5px solid #fff;
  border-radius: 50%;
}
.nav-toggler.toggler-open span:before {
  transform: translateY(0px) rotate(45deg);
}
.nav-toggler.toggler-open span:after {
  transform: translateY(-3px) rotate(-45deg);
}

@media screen and (max-width: 950px) {
  .container {
    max-width: 720px;
  }
  /* navbar css for mobile start */
  .nav-toggler {
    display: block;
  }
  .navbar {
    min-height: 60px;
    height: 70px;
  }
  .navbar ul {
    padding: 0px;
    position: absolute;
    width: 100%;
    height: calc(100vh - 60px);
    left: 0;
    top: 70px;
    flex-direction: column;
    align-items: center;
    /* border-top: 1px solid #444; */
    /* background-color: rgba(0,0,0,.75); */
    /* background-color: #f47622; */
    background: linear-gradient(90.32deg, #7932E8 28.37%, #9B51E0 97.47%);
    max-height: 0;
    overflow: hidden;
    transition: 0.3s;
  }
  .logo-image {
    margin: 0px 0px;
  }
  .navbar ul li {
    width: 100%;
    text-align: center;
  }
  .navbar ul li div {
    padding: 25px;
  }
  .navbar ul li div.responsive-remove {
    display: none;
  }
  .navbar ul li div.responsive-add {
    display: block;
  }
  .navbar ul li button {
    margin-top: 24%;
  }
  /* .navbar ul li div span a{
    color: #dadede;
  }
   */
  .navbar ul.open {
    max-height: 100vh;
    overflow: visible;
    animation: growDown 1.6s ease-in-out forwards;
    /* animation: growDown 300ms ease-in-out forwards; */
    transform-origin: top center;
  }

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    25% {
      transform: scaleY(1.2);
    }
    50% {
      transform: scaleY(1);
    }
    75% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }

  .intro-area h2 {
    font-size: 36px;
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 951px) and (max-width: 997px) {
  .square a {
    padding: 15px;
  }
}
</style>
