import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomePage from '../views/HomePage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'HomePage',
    // component: HomePage
    component: () => import(/* webpackPrefetch: true */ '../views/HomePage.vue'),
    delay: 200
  },
  {
    path: '/ourstory',
    name: 'OurStory',
    component: () => import(/* webpackPrefetch: true */ '../views/OurStory.vue')
  },
  
  {
    path: '/impact',
    name: 'Impact',
    component: () => import(/* webpackPrefetch: true */ '../views/Impact.vue')
  },
  {
    path: '/careers',
    name: 'Careers',
    component: () => import(/* webpackPrefetch: true */ '../views/Careers.vue')
  },
  {
    path: '/partners_and_media',
    name: 'Partners_And_Media',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackPrefetch: true */ '../views/company/Partners_And_Media.vue')
  },
  {
    path: '/board_and_investor',
    name: 'Board_And_Investor',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackPrefetch: true */ '../views/company/Board_And_Investor.vue')
  },
  {
    path: '/help_and_faq',
    name: 'Help_And_Faq',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackPrefetch: true */ '../views/company/Help_And_Faq.vue')
  },
  {
    path: '/contact_us',
    name: 'ContactUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackPrefetch: true */ '../views/company/ContactUs.vue')
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackPrefetch: true */ '../views/footer/privacy_policy.vue')
  },
  {
    path: '/terms_of_service',
    name: 'TermsOfService',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackPrefetch: true */ '../views/footer/terms_of_service.vue')
  },
  {
    path: '/support',
    redirect: '/contact_us'
  },
  {
    path: '/legal_notes',
    name: 'LegalNotes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackPrefetch: true */ '../views/footer/legal_notes.vue')
  },
  {
    path: '/personal_information',
    name: 'PersonalInformation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackPrefetch: true */ '../views/footer/personal_information.vue')
  },
  {
    path: '/matriclive_web',
    name: 'MatricLiveWeb',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackPrefetch: true */ '../views/MatricLiveWeb.vue')
  },
  {
    path: '/404',
    alias: "*",
    name: 'MatricLiveWeb',
    component: () => import(/* webpackPrefetch: true */ '../views/notFound/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router
